.events {
  .iframe2 {
    border: none;
    overflow: hidden;
  }

  img.eventImage {
    // width:100px ;
    height: 277px;
  }
}
