.materials {
  .banner-area {
    background-position: center bottom;
  }
  .download {
    margin: 10px;

    i {
      margin-right: 10px;
      color: black;
    }
  }
}
