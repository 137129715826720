.home {
  .slide-sub-title,
  .slide-title,
  .slider-description {
    text-shadow: -5px -2px 10px rgba(0, 0, 0, 0.6);
  }

  .item0 {
    background-image: url(../../assets/images/banner/4.jpg);
    background-size: cover;
    background-position: center center !important;
    height: 100vh !important;
  }

  .item1 {
    background-image: url(../../assets/images/slider/papa.jpg);
    background-size: cover !important;
    background-position: center center !important;
    height: 100vh !important;
  }

  .item2 {
    background-image: url(../../assets/images/banner/5.jpg);
    background-size: cover !important;
    background-position: center center !important;
    height: 100vh !important;
  }

  .item3 {
    background-image: url(../../assets/images/banner/6.jpg);
    background-size: cover !important;
    background-position: center center !important;
    height: 100vh !important;
  }

  .carousel-item {
    min-height: 100vh !important;
  }
}
