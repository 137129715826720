*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #2154cf;
  border-radius: 2px;
}
