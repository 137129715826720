/*-- Banner --*/

.banner-area {
  position: relative;
  min-height: 500px;
  color: #fff;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  backface-visibility: hidden;

  .container {
    position: relative;
  }
}

.banner-title {
  color: #fff;
  font-size: 100px;
  margin-bottom: 30px;
  text-align: center;
  text-shadow: -5px -2px 10px rgba(0, 0, 0, 0.6);
}

.banner-heading {
  padding: 100px 0 0 0;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-height: 500px;
}

.breadcrumb {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0;
  background: none;
  font-size: 16px;
  font-weight: 300;

  li {
    &:not(:last-child) {
      &:after {
        content: "/";
        margin: 0 5px;
      }
    }
  }

  a:hover {
    color: #fff;
  }
}
