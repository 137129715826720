.devotional {
  .devotional-card {
    h3 {
      margin-bottom: 0;
    }
  }

  .section-title {
    text-align: left;
    display: block;
  }

  ul li {
    list-style: inside;
  }
}

.catalog {
  margin-top: 20px;
  display: block;

  .catalog-title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .catalog-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 30px;
    .catalog-item {
      width: 250px;

      img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 350px;
      }

      .bar {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }

  .disclaimer {
    text-align: center;
  }
}
