/* Intro Area 
================================================== */
.intro {
  .ts-intro {
    padding-bottom: 0px;

    .row {
      align-items: center;
    }
  }

  .ts-intro .btn-primary {
    margin-top: 15px;
  }

  .ts-intro .column-title {
    font-size: 36px;
    line-height: 44px;

    .p.mello-7 {
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }

  .ts-intro .pull-right.btn-primary {
    margin-right: 20px;
  }

  /* Intro Image Box */
  .intro-image-box {
    position: relative;
    margin: 20px;
  }

  .intro-image-content {
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    padding: 30px;
    z-index: 1;
    width: 345px;
    height: 460px;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    -webkit-box-shadow: 0 20px 25px 15px rgba(0, 0, 0, 0.35);
    box-shadow: 0 20px 25px 15px rgba(0, 0, 0, 0.35);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }

  .intro-image-content h3 {
    font-size: 180px;
    line-height: 100%;
    margin: 50px 0 0;
  }

  .intro-image-content p {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
  }

  .intro-shape:after {
    content: "";
    position: absolute;
    right: 0;
    z-index: -1;
    border-left: 400px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 400px solid #2154cf;
    bottom: -40px;
  }

  .intro-desc {
    padding-bottom: 0;
  }
}
