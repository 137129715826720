@import url(https://fonts.googleapis.com/css?family=Asap:400,400i,500,500i,600,600i,700,700i);

@font-face {
   font-family: 'gilroy';
   src: url('../assets/fonts/gilroy/gilroy-light-webfont.eot');
   src: url('../assets/fonts/gilroy/gilroy-light-webfont.eot?#iefix') format("embedded-opentype"), url('../assets/fonts/gilroy/gilroy-light-webfont.woff2') format("woff2"), url('../assets/fonts/gilroy/gilroy-light-webfont.woff') format("woff"), url('../assets/fonts/gilroy/gilroy-light-webfont.ttf') format("truetype"), url('../assets/fonts/gilroy/gilroy-light-webfont.svg#gilroylight') format("svg");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: 'gilroy';
   src: url('../assets/fonts/gilroy/gilroy-extrabold-webfont.eot');
   src: url('../assets/fonts/gilroy/gilroy-extrabold-webfont.eot?#iefix') format("embedded-opentype"), url('../assets/fonts/gilroy/gilroy-extrabold-webfont.woff2') format("woff2"), url('../assets/fonts/gilroy/gilroy-extrabold-webfont.woff') format("woff"), url('../assets/fonts/gilroy/gilroy-extrabold-webfont.ttf') format("truetype"), url('../assets/fonts/gilroy/gilroy-extrabold-webfont.svg#gilroyextrabold') format("svg");
   font-weight: 700;
   font-style: normal;
}