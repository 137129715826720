.donation {
  &.banner-area {
    background-image: url(../../assets/images/banner/partner-us.jpg) !important;
  }
  img.card {
    height: 55px;
    background: none;
  }

  input {
    background-color: white !important;
    border-radius: 6px !important;
  }

  p.lead {
    margin-left: 80px;
    margin-right: 80px;
  }
}
