/* Header area
================================================== */

/* Sticky Menu  */

#header.fixed,
.site-nav-inner.fixed,
#header.header-standard.fixed,
#header.header-standard.fixed.highlight,
.site-nav-inner.site-navigation.fixed,
.site-nav-inner.site-navigation.fixed.nav-transparent {
   position: fixed;
   width: 100%;
   top: 0;
   left: 0;
   z-index: 999;
   box-shadow: 0px 13px 35px -12px rgba(35, 35, 35, 0.15);
   animation: menusticky 0.7s ease-in-out;
}

.header-trans-leftbox.fixed {
   background: rgba(31, 31, 34, 0.9);
}

#header.fixed.header-trans-leftbox .header-wrapper:before {
   background: none;
}

.header-trans-leftbox.fixed .header-wrapper {
   background: none;
}

#header.header-standard.header-transparent.fixed {
   background: rgba(31, 31, 34, 0.9);
}

#header.header-standard.fixed {
   background: #fff;
}

#header.header-standard.fixed.highlight {
   background: #0F2765;
}

.site-nav-inner.site-navigation.fixed {
   background: #0F2765;
}

.site-nav-inner.site-navigation.nav-transparent.fixed {
   background: rgba(31, 31, 34, 0.9);
}

.header.nav-down.nav-box {
   .site-nav-inner.fixed {
      background: #0F2765;
   }
}

.header.nav-down.nav-boxed-transparent {
   .site-nav-inner.fixed {
      background: #0F2765;
   }
}

/* Sticky Menu Animation*/

@keyframes menusticky {
   0% {
      margin-top: -120px;
      opacity: 0;
   }

   50% {
      margin-top: -64px;
      opacity: 0;
   }

   100% {
      margin-top: 0;
      opacity: 1;
   }
}

/* Header default */

.header-trans-leftbox.navbar-fixed {
   background: rgb(0, 0, 0);
}

.header-trans-leftbox.navbar-fixed .header-wrapper {
   bottom: 0;
   background: rgba(0, 0, 0, 0.0);
}

.header-trans-leftbox.navbar-fixed .header-trans-leftbox .header-wrapper:before {
   display: none;
}

.header-trans-leftbox .header-wrapper {
   background: rgba(0, 0, 0, 0.35);
   color: #fff;
   position: relative;

   &:before {
      content: "";
      position: absolute;
      background: rgba(0, 0, 0, 0.35);
      display: block;
      width: 300%;
      height: 100%;
      right: -300%;
      top: 0;
   }
}

.header.header-box-left {
   height: 60px;
   box-shadow: none;
   position: absolute;
   width: 100%;
}

.header-box-left .header-wrapper {
   bottom: -15px;
}

.header-wrapper {
   background: #0f2765;
   color: #fff;
   position: relative;

   &:before {
      content: "";
      position: absolute;
      background: #0f2765;
      display: block;
      width: 300%;
      height: 100%;
      right: -300%;
      top: 0;
   }
}

/* Header 2 */

.header-standard {
   &.header-transparent {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      top: 50px;

      ul.navbar-nav>li>a {
         color: #fff;
      }

      @media screen and (min-width:767px) {
         .logo {
            padding-left: 0;
         }
      }

      .navbar-nav .active>.nav-link {
         color: #fff;
      }

      &.navbar-fixed {
         background: #000;
         top: 0;
      }
   }

   .navbar-nav .active>.nav-link {
      color: #252a37;
   }

   background: #fff;
   box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
   z-index: 2;

   ul.navbar-nav>li {
      >a {
         color: #fff;

         @media screen and (min-width: 992px) {
            color: #252a37;
         }

         line-height: 80px;
      }

      &:hover>a {
         color: #fc6a2a;
      }
   }

   .container {
      position: relative;
   }

   .site-nav-inner {
      @media screen and (min-width: 992px) {
         padding-right: 50px;
      }
   }

   @media screen and (max-width: 767px) {
      .container {
         max-width: 100%;
         padding: 0;
      }

      .site-nav-inner {
         padding-right: 0;
      }
   }

   .nav-search {
      top: 22px;
      right: 15px;
      width: 45px;
      height: 45px;
      line-height: 45px;
      background: #2154cf;
      border-radius: 100%;

      span {
         top: 3px;
         position: relative;
      }
   }

   .search-block .search-close {
      color: #fff;
      top: -68px;
      right: 0;
      background: #2154cf;
      opacity: 1;
      width: 45px;
      height: 45px;
      line-height: 30px;
      border-radius: 100%;
   }

   &.header-boxed {
      background: none;
      position: absolute;
      box-shadow: none;
      top: 50px;
      left: 0;
      right: 0;

      .site-nav-inner {
         background: #fff;
         z-index: 1;
         margin: 0;
         padding: 0 20px;
      }

      .nav-search {
         right: 40px;
         background: #0f2765;
      }

      .search-block .search-close {
         right: 25px;
         background: #0f2765;
         top: -68px;
      }

      &.highlight {
         .row {
            background: #0f2765;
         }

         ul.navbar-nav>li>a {
            color: #fff;
         }
      }
   }
}

.site-navigation {
   &.navdown {
      background: #0f2765;
      position: relative;

      .navbar-collapse {
         padding-left: 0;
      }

      ul.navbar-nav>li>a {
         line-height: 60px;
      }
   }

   &.navigation {
      &.navdown {
         background: #0f2765;
      }
   }

   .nav-search {
      top: 0;
      z-index: 2;
      width: 70px;
      height: 70px;
      line-height: 70px;
      right: 15px;
      background: rgba(0, 0, 0, 0.2);
   }

   .search-block .search-close {
      top: -70px;
      z-index: 2;
      width: 70px;
      height: 70px;
      line-height: 50px;
      right: 0;
      background: hsla(0, 0%, 0%, 0.2);
   }
}

/* Header 3 */

header {
   &.header-standard {
      &.header-light {

         .navbar-nav .active>.nav-link,
         .navbar-nav .nav-link:focus {
            color: #fff;

            @media screen and (min-width: 992px) {
               color: #252a37;
            }

            &:hover {
               color: #F2672A;
            }
         }
      }
   }
}

/* Header 5 */

.header-standard {
   .navbar-nav .nav-link:focus {
      color: #252a37;
      transition: all 0.3s;
   }

   &.header-boxed {
      &.highlight {
         .navbar-nav .nav-link:focus {
            color: #fff;
            transition: all 0.3s;
         }

         .site-nav-inner {
            background: #0f2765;
         }

         .nav-search {
            background: #2154cf;
         }

         .search-block {
            .search-close {
               background: #2154cf;
            }
         }
      }

      .navbar-nav .nav-link:focus {
         color: #252a37;
         transition: all 0.3s;
      }
   }
}

/* Header 7 */

.header {
   &.nav-down {
      &.nav-box {
         height: 145px;

         .site-nav-inner {
            .container {
               position: relative;
            }

            .search-block {
               .search-close {
                  top: -60px;
                  right: 20px;
                  line-height: 30px;
               }
            }
         }

         .site-navigation {
            background: none;

            &.navdown {
               &.navbar {
                  position: relative;
                  background: #0f2765;
                  bottom: 0;
                  z-index: 9;
                  margin: 0;
                  padding: 0 15px;
               }
            }
         }

         .nav-search {
            right: 15px;
            top: 0;
            z-index: 9;
            width: 70px;
            height: 70px;
            line-height: 70px;
            background: rgba(0, 0, 0, 0.2);
         }
      }

      &.nav-boxed-transparent {
         color: #fff;
         background: none;
         box-shadow: none;
         position: absolute;
         width: 100%;
         left: 0;
         top: 40px;
         z-index: 2;
         height: auto;
         transition: 350ms;
         -o-transition: 350ms;
         transition: 350ms;

         .site-navigation.navdown {
            background: none;

            .row {
               position: relative;
               background: #0f2765;
               bottom: 0;
               z-index: 1;
               margin: 0;
            }
         }

         p,
         i {
            color: #fff !important;
         }
      }
   }

   &.header-solid {
      background: #0f2765;
      color: #fff;

      .top-info {

         li .info-wrapper p,
         .info-icon {
            color: #fff;
         }

         li {
            .info-wrapper:after {
               background: rgba(255, 255, 255, 0.1);
            }

            &.last .info-wrapper:after {
               background: none;
            }
         }
      }
   }
}

/* Header 8 */

/* Logo area */

@media screen and (max-width: 992px) {
   .top-info-wrraper {
      display: none;
   }
}

.site-nav-inner {
   &.navigation-inner {
      .container {
         position: relative;
      }

      nav {
         &.navbar {
            &.site-navigation {
               &.navdown {
                  background: #0f2765;
                  padding: 0 15px;
               }
            }
         }
      }

      .nav-search {
         top: 0;
         right: 15px;
         line-height: 70px;
         width: 70px;
         height: 70px;
         background: rgba(0, 0, 0, .2);
      }

      .search-block {
         .search-close {
            top: -60px;
            right: 20px;
            line-height: 30px;
         }
      }
   }
}

/* Header 9 */

.site-navigation.navdown.nav-transparent {
   background: transparent;
   position: absolute;
   border-bottom: 1px solid rgba(255, 255, 255, 0.3);
   left: 0;
   right: 0;
   z-index: 1;
}

/*-- Header common --*/

.header-trans-leftbox,
.header-transparent {
   background: none;
   box-shadow: none;
   transition: 350ms;
}

.header {
   background: #fff;
   z-index: 2;
}

.navbar-fixed {
   z-index: 99999;
   position: fixed;
   opacity: .98;
   width: 100%;
   top: 0;
   animation: fadeInDown 800ms;
   -moz-animation: fadeInDown 800ms;
   -ms-animation: fadeInDown 800ms;
   -o-animation: fadeInDown 800ms;
   animation: fadeInDown 800ms;
}

/*-- Logo --*/

.header-trans-leftbox {
   .logo {
      padding-left: 30px;
   }
}

.logo {
   height: 90px;
   display: table;

   a {
      display: table-cell;
      vertical-align: middle;
   }
}

.logo-area {
   display: flex;
   flex: 0 0 100%;
   flex-wrap: wrap;
}

.header.nav-down .logo {
   height: 110px;
}

/* header right */

.header-right {
   float: right;
}

.top-info {
   list-style: none;
   margin: 35px 0 0;
   padding: 0;
   float: right;

   li {
      display: inline-block;

      &:last-child {
         margin-right: 0;
         padding-right: 0;
         border-right: 0;
      }
   }

   .info-icon {
      font-size: 20px;
      color: #2154cf;
      display: inline-block;
      top: -15px;
      position: relative;
      margin-right: 10px;
   }

   .info-wrapper {
      display: inline-block;
      margin-right: 30px;
      position: relative;
      padding-right: 10px;

      &:after {
         position: absolute;
         content: '';
         background: #ddd;
         right: -15px;
         width: 1px;
         height: 35px;
         top: 4px;
      }
   }

   li {
      .info-wrapper p {
         font-size: 16px;
         margin-bottom: 0;
         line-height: normal;
         font-weight: 700;
         color: #252a37;
      }

      &.last .info-wrapper:after {
         background: none;
      }

      .info-wrapper p.info-subtitle {
         line-height: normal;
         font-size: 14px;
         font-weight: 400;
         color: #7a7a7a;
         margin-top: 5px;
      }
   }
}