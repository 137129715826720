.ts-chart {
  color: #ffffff;
  background-color: #1c1c24;
  background-image: url("../../../assets/images/parallax/1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50% 50%;
  padding: 100px 0;
}
