.testimonial {
  .row.testimonies {
    overflow-x: scroll;
    flex-wrap: nowrap;
  }

  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}
