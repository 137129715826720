.boundary {
  *,
  :before,
  :after {
    box-sizing: border-box;
  }

  & {
    display: flex;
    align-items: center;
    background: #e3f2fd;
    min-width: 275px;
    width: 100vw;
    height: 100vh;
    padding-left: 20px;
    margin: 0 0;
    overflow: hidden;
    color: #138ff2;
    font-family: Roboto;
  }

  .wrapper {
    text-align: center;
    vertical-align: middle;
    width: 100%;
    margin: 20px auto;
    flex-grow: 2;
    width: 40vw;
    max-width: 500px;
    margin: 0 auto;
  }

  h1 {
    margin: 0;
    font-size: 6em;
    font-weight: 100;
  }

  p {
    width: 95%;
    font-size: 1.5em;
    line-height: 1.4;
  }

  .buttons {
    white-space: nowrap;
    display: inline-block;
  }

  span {
    display: block;
    text-transform: uppercase;
    color: #b9ddfb;
    letter-spacing: 1.5px;
    text-align: center;
  }
  a {
    display: inline-block;
    padding: 0.8em 1em;
    margin-right: 1em;
    margin-bottom: 1em;
    border: 3px solid #b9ddfb;
    color: #138ff2;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.2em;
    position: relative;
    overflow: hidden;
    transition: 0.3s;
    &:hover {
      color: #e3f2fd;
      &:before {
        top: 0;
      }
    }
    &:before {
      content: "";
      background: #138ff2;
      height: 100%;
      width: 100%;
      position: absolute;
      top: -100%;
      left: 0;
      transition: 0.3s;
      z-index: -1;
    }
  }

  // BLACKHOLE

  $size: 75px;
  $border: $size/15;

  .space {
    width: $size;
    height: calc(50vh + #{$size/2});
    border-top-left-radius: $size/2;
    border-top-right-radius: $size/2;
    overflow: hidden;
    margin: calc(50vh - #{$size/2}) auto 0 auto;
    position: relative;
    pointer-events: none;
    -webkit-transform: translateZ(0);
  }

  .blackhole {
    border: $border solid #1674d1;
    height: $size;
    width: $size;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    &:after {
      content: "";
      height: calc(100% + #{$border * 2});
      width: calc(100% + #{$border * 2});
      border: $border solid #1674d1;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-radius: 50%;
      position: absolute;
      top: -$border;
      left: -$border;
      z-index: 5;
      transform: rotate(45deg);
    }
  }

  .ship {
    height: $size * 2;
    width: $size - $border * 4;
    margin-left: $border * 2;
    background: url("https://cbwconline.com/IMG/Codepen/Space%20Ship.svg")
      center/contain no-repeat;
    animation: blackhole 4s infinite linear;
    position: absolute;
    bottom: -$size * 2;
  }

  @keyframes blackhole {
    to {
      transform: translateY(-100vh);
    }
  }

  @media (max-width: 600px) {
    body {
      margin: 0 5vw;
    }
  }
}
