.blog-tile {
  background: #fff;
  border: 0;
  border-top: 0;
  padding: 40px 30px;
  margin: 15px 0;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 2px 20px 0 rgba(0, 0, 0, 0.05);

  .img-center {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .content-info {
    padding-left: 30px;

    .btn-light {
      font-weight: 800;
      transition: all 0.3s;
      background-color: transparent;
      border-color: transparent;

      i {
        padding-left: 5px;
      }

      &:hover {
        color: #2154cf;
        background-color: transparent;
      }
    }
  }

  .content-title {
    font-size: 30px;
    line-height: 5px;
    margin-top: 25px;
    margin-bottom: 25px;
    display: inline-block;

    @media screen and (max-width: 479px) {
      line-height: 36px;
    }
  }
}
