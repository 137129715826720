@font-face {
  font-family: 'iconfont';
  src:
    url('../assets/fonts/iconfont/iconfont.ttf?2p4rfb') format('truetype'),
    url('../assets/fonts/iconfont/iconfont.woff?2p4rfb') format('woff'),
    url('../assets/fonts/iconfont/iconfont.svg?2p4rfb#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;


  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-deal:before {
  content: "\e924";
}
.icon-left-arrow2:before {
  content: "\e931";
}
.icon-quote2:before {
  content: "\e937";
}
.icon-right-arrow2:before {
  content: "\e938";
}
.icon-coins-2:before {
  content: "\e902";
}
.icon-commerce-2:before {
  content: "\e903";
}
.icon-monitor:before {
  content: "\e904";
}
.icon-graphic-3:before {
  content: "\e905";
}
.icon-business:before {
  content: "\e906";
}
.icon-graphic-2:before {
  content: "\e907";
}
.icon-commerce-1:before {
  content: "\e908";
}
.icon-hammer:before {
  content: "\e909";
}
.icon-graphic-1:before {
  content: "\e90a";
}
.icon-graphic:before {
  content: "\e90b";
}
.icon-justice-1:before {
  content: "\e90c";
}
.icon-line:before {
  content: "\e90d";
}
.icon-money-3:before {
  content: "\e90e";
}
.icon-chart:before {
  content: "\e90f";
}
.icon-commerce:before {
  content: "\e910";
}
.icon-agenda:before {
  content: "\e911";
}
.icon-money-2:before {
  content: "\e912";
}
.icon-justice:before {
  content: "\e913";
}
.icon-money-1:before {
  content: "\e914";
}
.icon-money:before {
  content: "\e915";
}
.icon-technology:before {
  content: "\e916";
}
.icon-coins-1:before {
  content: "\e917";
}
.icon-coins:before {
  content: "\e918";
}
.icon-left-arrow:before {
  content: "\e919";
}
.icon-bank:before {
  content: "\e91a";
}
.icon-calculator:before {
  content: "\e91b";
}
.icon-chart2:before {
  content: "\e91c";
}
.icon-chart22:before {
  content: "\e91d";
}
.icon-checked:before {
  content: "\e91e";
}
.icon-clock3:before {
  content: "\e91f";
}
.icon-comment:before {
  content: "\e920";
}
.icon-comments:before {
  content: "\e921";
}
.icon-consult:before {
  content: "\e922";
}
.icon-consut2:before {
  content: "\e923";
}
.icon-download:before {
  content: "\e925";
}
.icon-envelope:before {
  content: "\e926";
}
.icon-euro:before {
  content: "\e927";
}
.icon-folder:before {
  content: "\e928";
}
.icon-invest:before {
  content: "\e929";
}
.icon-loan:before {
  content: "\e92a";
}
.icon-map-marker2:before {
  content: "\e92b";
}
.icon-mutual-fund:before {
  content: "\e92c";
}
.icon-percent:before {
  content: "\e92d";
}
.icon-phone3:before {
  content: "\e92e";
}
.icon-pie-chart2:before {
  content: "\e92f";
}
.icon-play:before {
  content: "\e930";
}
.icon-savings:before {
  content: "\e932";
}
.icon-search:before {
  content: "\e933";
}
.icon-tag:before {
  content: "\e934";
}
.icon-tags:before {
  content: "\e935";
}
.icon-tax:before {
  content: "\e936";
}
.icon-vplay:before {
  content: "\e900";
}
.icon-newsletter:before {
  content: "\e901";
}
.icon-mobile2:before {
  content: "\e000";
}
.icon-laptop2:before {
  content: "\e001";
}
.icon-desktop2:before {
  content: "\e002";
}
.icon-tablet2:before {
  content: "\e003";
}
.icon-phone2:before {
  content: "\e004";
}
.icon-document:before {
  content: "\e005";
}
.icon-documents:before {
  content: "\e006";
}
.icon-search2:before {
  content: "\e007";
}
.icon-clipboard2:before {
  content: "\e008";
}
.icon-newspaper:before {
  content: "\e009";
}
.icon-notebook:before {
  content: "\e00a";
}
.icon-book-open:before {
  content: "\e00b";
}
.icon-browser:before {
  content: "\e00c";
}
.icon-calendar2:before {
  content: "\e00d";
}
.icon-presentation:before {
  content: "\e00e";
}
.icon-picture:before {
  content: "\e00f";
}
.icon-pictures:before {
  content: "\e010";
}
.icon-video:before {
  content: "\e011";
}
.icon-camera2:before {
  content: "\e012";
}
.icon-printer:before {
  content: "\e013";
}
.icon-toolbox:before {
  content: "\e014";
}
.icon-briefcase2:before {
  content: "\e015";
}
.icon-wallet:before {
  content: "\e016";
}
.icon-gift2:before {
  content: "\e017";
}
.icon-bargraph:before {
  content: "\e018";
}
.icon-grid:before {
  content: "\e019";
}
.icon-expand2:before {
  content: "\e01a";
}
.icon-focus:before {
  content: "\e01b";
}
.icon-edit2:before {
  content: "\e01c";
}
.icon-adjustments:before {
  content: "\e01d";
}
.icon-ribbon:before {
  content: "\e01e";
}
.icon-hourglass2:before {
  content: "\e01f";
}
.icon-lock2:before {
  content: "\e020";
}
.icon-megaphone:before {
  content: "\e021";
}
.icon-shield2:before {
  content: "\e022";
}
.icon-trophy2:before {
  content: "\e023";
}
.icon-flag2:before {
  content: "\e024";
}
.icon-map2:before {
  content: "\e025";
}
.icon-puzzle:before {
  content: "\e026";
}
.icon-basket:before {
  content: "\e027";
}
.icon-envelope2:before {
  content: "\e028";
}
.icon-streetsign:before {
  content: "\e029";
}
.icon-telescope:before {
  content: "\e02a";
}
.icon-gears2:before {
  content: "\e02b";
}
.icon-key2:before {
  content: "\e02c";
}
.icon-paperclip2:before {
  content: "\e02d";
}
.icon-attachment:before {
  content: "\e02e";
}
.icon-pricetags:before {
  content: "\e02f";
}
.icon-lightbulb:before {
  content: "\e030";
}
.icon-layers:before {
  content: "\e031";
}
.icon-pencil2:before {
  content: "\e032";
}
.icon-tools:before {
  content: "\e033";
}
.icon-tools-2:before {
  content: "\e034";
}
.icon-scissors2:before {
  content: "\e035";
}
.icon-paintbrush:before {
  content: "\e036";
}
.icon-magnifying-glass:before {
  content: "\e037";
}
.icon-circle-compass:before {
  content: "\e038";
}
.icon-linegraph:before {
  content: "\e039";
}
.icon-mic:before {
  content: "\e03a";
}
.icon-strategy:before {
  content: "\e03b";
}
.icon-beaker:before {
  content: "\e03c";
}
.icon-caution:before {
  content: "\e03d";
}
.icon-recycle2:before {
  content: "\e03e";
}
.icon-anchor2:before {
  content: "\e03f";
}
.icon-profile-male:before {
  content: "\e040";
}
.icon-profile-female:before {
  content: "\e041";
}
.icon-bike:before {
  content: "\e042";
}
.icon-wine:before {
  content: "\e043";
}
.icon-hotairballoon:before {
  content: "\e044";
}
.icon-globe2:before {
  content: "\e045";
}
.icon-genius:before {
  content: "\e046";
}
.icon-map-pin2:before {
  content: "\e047";
}
.icon-dial:before {
  content: "\e048";
}
.icon-chat:before {
  content: "\e049";
}
.icon-heart2:before {
  content: "\e04a";
}
.icon-cloud2:before {
  content: "\e04b";
}
.icon-upload2:before {
  content: "\e04c";
}
.icon-download2:before {
  content: "\e04d";
}
.icon-target:before {
  content: "\e04e";
}
.icon-hazardous:before {
  content: "\e04f";
}
.icon-piechart:before {
  content: "\e050";
}
.icon-speedometer:before {
  content: "\e051";
}
.icon-global:before {
  content: "\e052";
}
.icon-compass2:before {
  content: "\e053";
}
.icon-lifesaver:before {
  content: "\e054";
}
.icon-clock:before {
  content: "\e055";
}
.icon-aperture:before {
  content: "\e056";
}
.icon-quote:before {
  content: "\e057";
}
.icon-scope:before {
  content: "\e058";
}
.icon-alarmclock:before {
  content: "\e059";
}
.icon-refresh2:before {
  content: "\e05a";
}
.icon-happy:before {
  content: "\e05b";
}
.icon-sad:before {
  content: "\e05c";
}
.icon-facebook2:before {
  content: "\e05d";
}
.icon-twitter2:before {
  content: "\e05e";
}
.icon-googleplus:before {
  content: "\e05f";
}
.icon-rss2:before {
  content: "\e060";
}
.icon-tumblr2:before {
  content: "\e061";
}
.icon-linkedin2:before {
  content: "\e062";
}
.icon-dribbble2:before {
  content: "\e063";
}
.icon-home:before {
  content: "\e800";
}
.icon-apartment:before {
  content: "\e801";
}
.icon-pencil:before {
  content: "\e802";
}
.icon-magic-wand:before {
  content: "\e803";
}
.icon-drop:before {
  content: "\e804";
}
.icon-lighter:before {
  content: "\e805";
}
.icon-poop:before {
  content: "\e806";
}
.icon-sun:before {
  content: "\e807";
}
.icon-moon:before {
  content: "\e808";
}
.icon-cloud:before {
  content: "\e809";
}
.icon-cloud-upload:before {
  content: "\e80a";
}
.icon-cloud-download:before {
  content: "\e80b";
}
.icon-cloud-sync:before {
  content: "\e80c";
}
.icon-cloud-check:before {
  content: "\e80d";
}
.icon-database:before {
  content: "\e80e";
}
.icon-lock3:before {
  content: "\e80f";
}
.icon-cog2:before {
  content: "\e810";
}
.icon-trash2:before {
  content: "\e811";
}
.icon-dice:before {
  content: "\e812";
}
.icon-heart3:before {
  content: "\e813";
}
.icon-star2:before {
  content: "\e814";
}
.icon-star-half2:before {
  content: "\e815";
}
.icon-star-empty:before {
  content: "\e816";
}
.icon-flag3:before {
  content: "\e817";
}
.icon-envelope3:before {
  content: "\e818";
}
.icon-paperclip:before {
  content: "\e819";
}
.icon-inbox:before {
  content: "\e81a";
}
.icon-eye:before {
  content: "\e81b";
}
.icon-printer2:before {
  content: "\e81c";
}
.icon-file-empty:before {
  content: "\e81d";
}
.icon-file-add:before {
  content: "\e81e";
}
.icon-enter:before {
  content: "\e81f";
}
.icon-exit:before {
  content: "\e820";
}
.icon-graduation-hat:before {
  content: "\e821";
}
.icon-license:before {
  content: "\e822";
}
.icon-music-note:before {
  content: "\e823";
}
.icon-film-play:before {
  content: "\e824";
}
.icon-camera-video:before {
  content: "\e825";
}
.icon-camera:before {
  content: "\e826";
}
.icon-picture2:before {
  content: "\e827";
}
.icon-book:before {
  content: "\e828";
}
.icon-bookmark:before {
  content: "\e829";
}
.icon-user2:before {
  content: "\e82a";
}
.icon-users:before {
  content: "\e82b";
}
.icon-shirt:before {
  content: "\e82c";
}
.icon-store:before {
  content: "\e82d";
}
.icon-cart:before {
  content: "\e82e";
}
.icon-tag2:before {
  content: "\e82f";
}
.icon-phone-handset:before {
  content: "\e830";
}
.icon-phone:before {
  content: "\e831";
}
.icon-pushpin:before {
  content: "\e832";
}
.icon-map-marker:before {
  content: "\e833";
}
.icon-map:before {
  content: "\e834";
}
.icon-location:before {
  content: "\e835";
}
.icon-calendar-full:before {
  content: "\e836";
}
.icon-keyboard:before {
  content: "\e837";
}
.icon-spell-check:before {
  content: "\e838";
}
.icon-screen:before {
  content: "\e839";
}
.icon-smartphone:before {
  content: "\e83a";
}
.icon-tablet:before {
  content: "\e83b";
}
.icon-laptop:before {
  content: "\e83c";
}
.icon-laptop-phone:before {
  content: "\e83d";
}
.icon-power-switch:before {
  content: "\e83e";
}
.icon-bubble:before {
  content: "\e83f";
}
.icon-heart-pulse:before {
  content: "\e840";
}
.icon-construction:before {
  content: "\e841";
}
.icon-pie-chart:before {
  content: "\e842";
}
.icon-chart-bars:before {
  content: "\e843";
}
.icon-gift:before {
  content: "\e844";
}
.icon-diamond2:before {
  content: "\e845";
}
.icon-dinner:before {
  content: "\e847";
}
.icon-coffee-cup:before {
  content: "\e848";
}
.icon-leaf:before {
  content: "\e849";
}
.icon-paw:before {
  content: "\e84a";
}
.icon-rocket:before {
  content: "\e84b";
}
.icon-briefcase:before {
  content: "\e84c";
}
.icon-bus2:before {
  content: "\e84d";
}
.icon-car2:before {
  content: "\e84e";
}
.icon-train:before {
  content: "\e84f";
}
.icon-bicycle2:before {
  content: "\e850";
}
.icon-wheelchair:before {
  content: "\e851";
}
.icon-select:before {
  content: "\e852";
}
.icon-earth:before {
  content: "\e853";
}
.icon-smile:before {
  content: "\e854";
}
.icon-sad2:before {
  content: "\e855";
}
.icon-neutral:before {
  content: "\e856";
}
.icon-mustache:before {
  content: "\e857";
}
.icon-alarm:before {
  content: "\e858";
}
.icon-bullhorn:before {
  content: "\e859";
}
.icon-volume-high:before {
  content: "\e85a";
}
.icon-volume-medium:before {
  content: "\e85b";
}
.icon-volume-low:before {
  content: "\e85c";
}
.icon-volume:before {
  content: "\e85d";
}
.icon-mic2:before {
  content: "\e85e";
}
.icon-hourglass:before {
  content: "\e85f";
}
.icon-undo:before {
  content: "\e860";
}
.icon-redo:before {
  content: "\e861";
}
.icon-sync:before {
  content: "\e862";
}
.icon-history:before {
  content: "\e863";
}
.icon-clock2:before {
  content: "\e864";
}
.icon-download3:before {
  content: "\e865";
}
.icon-upload:before {
  content: "\e866";
}
.icon-enter-down:before {
  content: "\e867";
}
.icon-exit-up:before {
  content: "\e868";
}
.icon-bug:before {
  content: "\e869";
}
.icon-code2:before {
  content: "\e86a";
}
.icon-link:before {
  content: "\e86b";
}
.icon-unlink:before {
  content: "\e86c";
}
.icon-thumbs-up:before {
  content: "\e86d";
}
.icon-thumbs-down:before {
  content: "\e86e";
}
.icon-magnifier:before {
  content: "\e86f";
}
.icon-cross:before {
  content: "\e870";
}
.icon-menu:before {
  content: "\e871";
}
.icon-list:before {
  content: "\e872";
}
.icon-chevron-up:before {
  content: "\e873";
}
.icon-chevron-down:before {
  content: "\e874";
}
.icon-chevron-left:before {
  content: "\e875";
}
.icon-chevron-right:before {
  content: "\e876";
}
.icon-arrow-up:before {
  content: "\e877";
}
.icon-arrow-down:before {
  content: "\e878";
}
.icon-arrow-left:before {
  content: "\e879";
}
.icon-arrow-right:before {
  content: "\e87a";
}
.icon-move:before {
  content: "\e87b";
}
.icon-warning:before {
  content: "\e87c";
}
.icon-question-circle:before {
  content: "\e87d";
}
.icon-menu-circle:before {
  content: "\e87e";
}
.icon-checkmark-circle:before {
  content: "\e87f";
}
.icon-cross-circle:before {
  content: "\e880";
}
.icon-plus-circle:before {
  content: "\e881";
}
.icon-circle-minus:before {
  content: "\e882";
}
.icon-arrow-up-circle:before {
  content: "\e883";
}
.icon-arrow-down-circle:before {
  content: "\e884";
}
.icon-arrow-left-circle:before {
  content: "\e885";
}
.icon-arrow-right-circle:before {
  content: "\e886";
}
.icon-chevron-up-circle:before {
  content: "\e887";
}
.icon-chevron-down-circle:before {
  content: "\e888";
}
.icon-chevron-left-circle:before {
  content: "\e889";
}
.icon-chevron-right-circle:before {
  content: "\e88a";
}
.icon-crop:before {
  content: "\e88b";
}
.icon-frame-expand:before {
  content: "\e88c";
}
.icon-frame-contract:before {
  content: "\e88d";
}
.icon-layers2:before {
  content: "\e88e";
}
.icon-funnel:before {
  content: "\e88f";
}
.icon-text-format:before {
  content: "\e890";
}
.icon-text-size:before {
  content: "\e892";
}
.icon-bold:before {
  content: "\e893";
}
.icon-italic:before {
  content: "\e894";
}
.icon-underline:before {
  content: "\e895";
}
.icon-strikethrough:before {
  content: "\e896";
}
.icon-highlight:before {
  content: "\e897";
}
.icon-text-align-left:before {
  content: "\e898";
}
.icon-text-align-center:before {
  content: "\e899";
}
.icon-text-align-right:before {
  content: "\e89a";
}
.icon-text-align-justify:before {
  content: "\e89b";
}
.icon-line-spacing:before {
  content: "\e89c";
}
.icon-indent-increase:before {
  content: "\e89d";
}
.icon-indent-decrease:before {
  content: "\e89e";
}
.icon-page-break:before {
  content: "\e8a2";
}
.icon-hand:before {
  content: "\e8a5";
}
.icon-pointer-up:before {
  content: "\e8a6";
}
.icon-pointer-right:before {
  content: "\e8a7";
}
.icon-pointer-down:before {
  content: "\e8a8";
}
.icon-pointer-left:before {
  content: "\e8a9";
}
